<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    Темное оформление
    <v-col cols="auto">
      <v-switch
        v-model="$vuetify.theme.dark"
        class="ma-0 pa-0"
        color="secondary"
        hide-details
        @click="setDarkmode($vuetify.theme.dark)"
      />
    </v-col>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="logoutAction"
    >
      Выйти
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
    }),

    computed: {
      ...mapState(['drawer', 'darkMode']),
      darkMode: {
        get () {
          return this.$store.state.darkMode
        },
        set (val) {
          this.$store.commit('SET_DARKMODE', val)
        },
      },
    },

    created () {
      this.$vuetify.theme.dark = this.$store.state.darkMode
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
        setDarkmode: 'SET_DARKMODE',
      }),
      logoutAction () {
        this.$store.dispatch('clearState')
        this.$router.push({ name: 'Авторизация' })
      },
    },

  }
</script>
